import React from 'react';
import { Layout } from '~/components/layout';

export default function NotFound() {
  return (
    <Layout>
      <p>Sorry, page not found!</p>
    </Layout>
  );
}
